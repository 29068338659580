const defaultState = { loading: false };
export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case "newSheet":
      return { ...state, [action.payload[0]]: action.payload[1] };
    case "loading":
      return { ...state, loading: action.payload.value };
    default:
      return { ...state };
  }
};

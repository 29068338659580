import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {useSelector, useDispatch} from "react-redux";
import {getUrlForSheet} from "../config/config";

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
    let setTable = props.setTable,
        setFrame = props.setFrame;
    const state = useSelector((state) => state),
        dispatch = useDispatch();
    let games = [],
        options = [];
    let appData = state["appdata"];
    if (appData) {
        games = Object.keys(appData);
        games.sort();
        options = games.map((appName) => appName);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = async (event) => {
        const appName = event.target.getAttribute("data-game");
        fetchData(appName);
        setAnchorEl(null);
    };

    const [timerId, setTimerId] = useState(null);
    useEffect(() => {
        return () => {
            if (!!timerId) {
                clearInterval(timerId);
            }
        }
    }, []);

    async function fetchData(appName) {
        if (!!timerId) {
            clearInterval(timerId);
        }
        async function updateData() {
            dispatch({
                type: "loading",
                payload: {value: true},
            });
            if (state[appName]) {
                setTable(["GAME", appName]);
                return;
            }
            console.log("app data", appData);
            await fetch(getUrlForSheet(appName))
                .then((response) => response.json())
                .then((json) => {
                    setFrame("");
                    setTable(["GAME", appName]);
                    dispatch({
                        type: "newSheet",
                        payload: [appName, json],
                    });
                });
            dispatch({
                type: "loading",
                payload: {value: false},
            });
        }
        await updateData();
        setTimerId(setInterval(updateData, 60 * 1000));
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        // maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        data-game={option}
                        // selected={option}
                        onClick={handleClose}
                    >
                        {appData[option]}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  table: {
    minWidth: "100vw",
  },
});

export default function BasicTable(props) {
  const classes = useStyles();
  const state = useSelector((state) => state);
  let header;
  const activeTable = props.activeTable;

  if (activeTable === "APP9-SCH" || activeTable === "APP-8STANDINGS") {
    if (activeTable === "APP-8STANDINGS") {
      header = [
        "NATTIE",
        "Overall",
        "Division",
        "Non-Div",
        "SOS",
        "PTS",
        "AVG",
        "PTS RANK",
        "MAX",
        "OSOS",
      ];
    } else {
      header = [
        "WEEK 1",
        "OWNER-AWAY",
        "SCORE-AWAY",
        "OWNER-HOME",
        "SCORE-HOME",
      ];
    }
    let numberOfRows = [];
    if (state[activeTable]) {
      numberOfRows = Object.keys(state[activeTable]["rows"]);
    }
    const appRow = numberOfRows.map((number) => {
      return state[activeTable]["rows"][number];
    });

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map((tableHeading) => {
                return <TableCell>{tableHeading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {activeTable === "APP-8STANDINGS" &&
              appRow.map((row) => (
                <TableRow key={row["NATTIE"]}>
                  <TableCell component="th" scope="row">
                    {row["NATTIE"]}
                  </TableCell>
                  <TableCell>{row["Overall"]}</TableCell>
                  <TableCell>{row["Division"]}</TableCell>
                  <TableCell>{row["Non-Div"]}</TableCell>
                  <TableCell>{row["SOS"]}</TableCell>
                  <TableCell>{row["PTS"]}</TableCell>
                  <TableCell>{row["AVG"]}</TableCell>
                  <TableCell>{row["PTS RANK"]}</TableCell>
                  <TableCell>{row["MAX"]}</TableCell>
                  <TableCell>{row["OSOS"]}</TableCell>
                </TableRow>
              ))}
            {activeTable === "APP9-SCH" &&
              appRow.map((row) => (
                <TableRow key={row["WEEK 1"]}>
                  <TableCell component="th" scope="row">
                    {row["WEEK 1"]}
                  </TableCell>
                  <TableCell>{row["OWNER-AWAY"]}</TableCell>
                  <TableCell>{row["SCORE-AWAY"]}</TableCell>
                  <TableCell>{row["OWNER-HOME"]}</TableCell>
                  <TableCell>{row["SCORE-HOME"]}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (activeTable[0] === "GAME") {
    header = ["Own", "Team", "Pts", "Comp"];
    let numberOfRows = [];
    if (state[activeTable[1]]) {
      numberOfRows = Object.keys(state[activeTable[1]]["rows"]);
    }
    const appRow = numberOfRows.map((number) => {
      return state[activeTable[1]]["rows"][number];
    });

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map((tableHeading) => {
                return <TableCell>{tableHeading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {appRow.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row["Owner"]}
                </TableCell>
                <TableCell>{row["NAME"]}</TableCell>
                <TableCell>{row["Scoring"]}</TableCell>
                <TableCell>{row["Status"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (activeTable[0] === "ROSTERS") {
    header = ["Player", "College", "POS", "YR"];
    let numberOfRows = [];
    if (state[activeTable[1]]) {
      numberOfRows = Object.keys(state[activeTable[1]]["rows"]);
    }
    const appRow = numberOfRows.map((number) => {
      return state[activeTable[1]]["rows"][number];
    });
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map((tableHeading) => {
                return <TableCell>{tableHeading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {appRow.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row["Player"]}
                </TableCell>
                <TableCell>{row["College"]}</TableCell>
                <TableCell>{row["POS"]}</TableCell>
                <TableCell>{row["YR"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return null;
}

import "./App.css";
import React from "react";
import { CircularProgress, Grid, useTheme } from "@material-ui/core";
import { Menu } from "./components/Menu";
import { sheet } from "./codeToImport/makeStyles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUrlForSheet } from "./config/config";

const useStyles = sheet;

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // line ups, podcast, appdata
    (async function loadEveryThingForMenu() {
      setIsLoading(true);
      try {
        const lineups = getUrlForSheet("APP10-22LINEUPS");
        const podcasts = getUrlForSheet("APP35-PODCAST");
        const appdata = getUrlForSheet("appdata");
        const [lineupRes, podcastRes, appdataRes] = await Promise.all([
          fetch(lineups),
          fetch(podcasts),
          fetch(appdata),
        ]);
        dispatch({
          type: "newSheet",
          payload: ["APP10-22LINEUPS", await lineupRes.json()],
        });
        dispatch({
          type: "newSheet",
          payload: ["APP35-PODCAST", await podcastRes.json()],
        });
        dispatch({
          type: "newSheet",
          payload: ["appdata", (await appdataRes.json()).rows[0]],
        });
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    })();
  }, [dispatch]);

  return (
    <Grid
      container
      style={{
        height: "100vh",
        width: "100vw",
      }}
      direction="column"
      alignItems={isLoading ? "center" : "flex-start"}
      justifyContent={isLoading ? "center" : "flex-start"}
      className="App"
    >
      <Menu class={classes} theme={theme} />
      {isLoading && <CircularProgress />}
    </Grid>
  );
}

export default App;

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUrlForSheet } from "../config/config";

export function Draw(props) {
  const classes = props.class;
  const theme = props.theme;
  const handleDrawerClose = props.closeDrawer;
  const open = props.open;
  const setTable = props.setTable,
    setFrame = props.setFrame;
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [linesupOpen, setLinesup] = useState(false);
  const [rosterOpen, setRoster] = useState(false);
  const [podcastOpen, setPodcast] = useState(false);

  const [timerId, setTimerId] = useState(null);

  async function fetchingData(sheet) {
    async function updateData() {
      console.log("going to update ", sheet);
      const string = getUrlForSheet(sheet);
      dispatch({
        type: "loading",
        payload: { value: true },
      });
      await fetch(string)
        .then((response) => response.json())
        .then((json) => {
          return dispatch({
            type: "newSheet",
            payload: [sheet, json],
          });
        });
      dispatch({
        type: "loading",
        payload: { value: false },
      });
      return Promise.resolve();
    }
    await updateData();
    setTimerId(setInterval(updateData, 60 * 1000));
  }

  function handler(text, additionalData = "", event) {
    if (!!timerId) {
      clearInterval(timerId);
    }
    switch (text) {
      case "CFFLHQ":
        setTable("");
        setFrame(
          '<iframe frameborder=0 src="http://digitallake.asia/nick/blog/index.php/cffl/" width="100%" ' +
            'height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>'
        );
        break;
      case "STANDINGS":
        fetchingData("APP-8STANDINGS");
        setTable("APP-8STANDINGS");
        setFrame("");
        break;
      case "SCH":
        fetchingData("APP9-SCH");
        setTable("APP9-SCH");
        setFrame("");
        break;
      case "LINESUPS":
        setFrame(additionalData);
        setTable("");
        break;
      case "ROSTERS":
        fetchingData(additionalData);
        setTable([text, additionalData]);
        setFrame("");
        break;
      case "CFFLHQ BLOG":
        setTable("");
        setFrame(
          '<iframe frameborder=0 src="http://digitallake.asia/nick/blog/" width="100%" height="100%" ' +
            'frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>'
        );
        break;
      default:
        return;
    }
    handleDrawerClose();
  }
  useEffect(() => {
    handler("CFFLHQ");
    return () => {
      if (!!timerId) {
        clearInterval(timerId);
      }
    };
  }, []);

  let linesups = [],
    podcast = [];
  let lineupsData = {},
    podcastData = {};
  if (state["APP10-22LINEUPS"]) {
    linesups = Object.keys(state["APP10-22LINEUPS"]["rows"]);
    lineupsData = state["APP10-22LINEUPS"]["rows"];
  }
  if (state["APP35-PODCAST"]) {
    podcast = Object.keys(state["APP35-PODCAST"]["rows"]);
    podcastData = state["APP35-PODCAST"]["rows"];
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {["CFFLHQ", "STANDINGS", "SCH"].map((text, index) => (
          <ListItem button key={text} onClick={() => handler(text)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
        <ListItem button key="LINEUPS" onClick={() => setLinesup(!linesupOpen)}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="LINEUPS" />
          {linesupOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {linesups.map((element) => (
          <Collapse in={linesupOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  handler("LINESUPS", lineupsData[element]["CODE"])
                }
              >
                <ListItemIcon />
                <ListItemText primary={lineupsData[element]["TITLE"]} />
              </ListItem>
            </List>
          </Collapse>
        ))}
        <ListItem button key="ROSTERS" onClick={() => setRoster(!rosterOpen)}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="ROSTERS" />
          {rosterOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {linesups.map((element) => {
          if (!lineupsData[element]["SHEET"]) {
            return;
          }

          return (
            <Collapse in={rosterOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    handler("ROSTERS", lineupsData[element]["SHEET"])
                  }
                >
                  <ListItemIcon />
                  <ListItemText primary={lineupsData[element]["TITLE"]} />
                </ListItem>
              </List>
            </Collapse>
          );
        })}
        <ListItem button key="PODCAST" onClick={() => setPodcast(!podcastOpen)}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="PODCAST" />
          {podcastOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {podcast.map((element) => (
          <Collapse in={podcastOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  handler("LINESUPS", podcastData[element]["LINK"])
                }
              >
                <ListItemIcon />
                <ListItemText
                  primary={
                    podcastData[element]["TITLE"] + podcastData[element]["DATE"]
                  }
                />
              </ListItem>
            </List>
          </Collapse>
        ))}
        <ListItem
          button
          key="CFFLHQ BLOG"
          onClick={() => handler("CFFLHQ BLOG")}
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="CFFLHQ BLOG" />
        </ListItem>
      </List>
      <Divider />
    </Drawer>
  );
}

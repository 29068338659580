import clsx from "clsx";
import React from "react";
import BasicTable from "./Table";
import parse from "html-react-parser";
import { LinearProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

export function Body(props) {
  const classes = props.class;
  const activeTable = props.activeTable;
  const open = props.open;
  let iframe = props.iframe;
  const isLoading = useSelector((state) => state.loading);
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      <div className={classes.drawerHeader} />
      {isLoading && (
        <LinearProgress
          style={{ position: "fixed", width: "100vw", height: "2px" }}
          color="secondary"
        />
      )}
      <BasicTable activeTable={activeTable} />
      {parse(iframe)}
    </main>
  );
}

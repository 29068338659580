import React from "react";
import clsx from "clsx";
import { Body } from "./Body";
import { Draw } from "./Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector } from "react-redux";
import LongMenu from "./LongMenu";
import { Typography } from "@material-ui/core";

export function Menu(props) {
  const classes = props.class;
  const state = useSelector((state) => state);

  const theme = props.theme;

  const [activeTable, setTable] = useState([]);

  const [iframe, setFrame] = useState("");

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isDrawerOpen,
        })}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="open drawer"
            onClick={() => setDrawerOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, isDrawerOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            color="textSecondary"
            style={{ fontFamily: "'Permanent Marker', cursive" }}
          >
            CFFLHQ - College Never Ends
          </Typography>
          <LongMenu setTable={setTable} setFrame={setFrame} />
        </Toolbar>
      </AppBar>
      <Draw
        class={classes}
        theme={theme}
        open={isDrawerOpen}
        closeDrawer={() => setDrawerOpen(false)}
        setTable={setTable}
        setFrame={setFrame}
      />
      <Body
        class={classes}
        open={isDrawerOpen}
        activeTable={activeTable}
        iframe={iframe}
      />
    </div>
  );
}
